// import React from 'react'
// import { css } from '@emotion/core'
// import { Link, graphql } from 'gatsby'

import React, { useEffect } from 'react'
// import { css } from '@emotion/core'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
// import { rhythm } from '../utils/typography'
import qs from 'querystring'

// import Layout from '../components/layout'

import {
  Layout,
  Header,
  Main,
  BotTemplate,
  FeatureItem,
  Grid,
  PriceTable,
  Section,
  Spacer,
  Cover,
  BOT_TEMPLATES,
  Footer,
  Testimonial,
  WhyUs,
  LinkButton,
} from '@aiya/aiya-web-ui'
import withLocation from '../libs/withLocation'
import { getEnv, randomId, stringify, toBase64 } from '@aiya/helper'
import { LiffCore } from '@line/liff/dist/lib/liff'
import { useSearchContentLazyQuery, useSearchContentQuery } from '@aiya/data-access'

type Props = {
  data: any
  navigate: any
}

const Home = ({ data, navigate }) => {
  let clientId = '1655259796'
  const getHost = () => {
    let host = 'cloud.aiya.ai'
    if (getEnv() === 'dev') {
      host = `dev-${host}`
      clientId = '1655211289'
    } else if (getEnv() === 'uat') {
      host = `uat-${host}`
      clientId = '1655211289'
    } else if (getEnv() === 'localhost') {
      host = `localhost:3002`
      clientId = '1655211289'
    }
    return host
  }

  const handleSignUp = (data) => {
    const host = getHost()
    const q = toBase64(stringify(data))
    const url = 'https://manager.line.biz/module/auth/v1/authorize'
    const query = {
      response_type: 'code',
      client_id: clientId,
      redirect_uri: `https://${host}/link-account`,
      scope: [
        'account:manage',
        'message:mark_as_read',
        'message:receive',
        'message:send',
        'profile:read',
      ].join(' '),
      state: q,
    }
    navigate(`${url}?${qs.stringify(query)}`)
  }

  const handleLogin = () => {
    const host = getHost()
    const q = toBase64(stringify(data))
    const url = `https://${host}/login`
    const query = {
      ref: 'line',
    }
    navigate(`${url}?${qs.stringify(query)}`)
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  // useEffect(() => {
  //   liff.init({ liffId: '1553392709-5pDY7dab' }).then(() => {
  //     if (liff.isLoggedIn()) {
  //       liff.getProfile().then((profile) => {
  //         console.log(profile)
  //       })
  //     }
  //   })
  // }, [])

  return (
    <>
      <Helmet>
        <title>AIYA</title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        <meta http-equiv="Cache-control" content="public, max-age=0, must-revalidate" />
      </Helmet>
      <Layout>
        <Header
          logoUrl="https://cloud.aiya.ai/logo.7a03573.png"
          onButtonClick={scrollToTop}
          onLoginClick={handleLogin}
        />
        <Main>
          <Cover onSignUp={handleSignUp} />
          <Section>
            <div className="title">ACRM Features</div>
            <Spacer height={30} />
            <Grid>
              {data.aiya?.searchContent?.data?.map((feature, key) => (
                <FeatureItem
                  key={key}
                  title={feature.body.title}
                  pictureUrl={feature.body.picture_url}
                />
              ))}
            </Grid>
          </Section>
          <Section highlight>
            <WhyUs />
          </Section>
          {/* ===================== XXXXX ================= */}
          <Section>
            <div className="title">สร้างไวด้วยเทมเพลทพร้อมใช้งาน</div>
            <div className="description">
              ง่ายๆ เพียงเลือกแชทบอท Template แล้วใส่ข้อมูลธุรกิจของคุณ ในยุควิกฤตเช่นนี้
              ร้านไหนบริการดี บริการไว ทันใจลูกค้า ได้เปรียบแน่นอน
            </div>
            <Spacer height={30} />
            <BotTemplate items={BOT_TEMPLATES} />
          </Section>
          <Testimonial />
          <Section>
            <div className="title">ค่าบริการรายเดือน</div>
            <Spacer height={30} />
            <PriceTable />
          </Section>
          <Section highlight>
            <div className="title">สอบถามข้อมูลเพิ่มเติม</div>
            <div className="description">
              หากมีข้อสงสัยใดเกี่ยวกับผลิตภัณฑ์ของเรา สามารถแชทพูดคุยสอบถามได้ตลอด 24 ช.ม.
            </div>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <LinkButton url="https://lin.ee/f1IsTXJ">ติดต่อฝ่ายลูกค้าสัมพันธ์</LinkButton>
            </div>
          </Section>
          <Footer />
        </Main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    # allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    #   totalCount
    #   edges {
    #     node {
    #       id
    #       frontmatter {
    #         title
    #         date(formatString: "DD MMMM, YYYY")
    #       }
    #       fields {
    #         slug
    #       }
    #       excerpt
    #     }
    #   }
    # }
    aiya {
      searchContent(input: { filters: { content_type: "features" } }) {
        data {
          body
          title
          id
        }
      }
    }
  }
`

// const Home = () => {
//   return <div>Hello</div>
// }

export default withLocation(Home)

// export default function Home() {
//   return (
//     <Layout>
//       <Header logoUrl="https://cloud.aiya.ai/logo.7a03573.png" />
//       <Main>
//         <Section>
//           <div>Cover</div>
//         </Section>
//         <Section highlight>
//           <div className="title">ACRM Features</div>
//           <Spacer height={30} />
//           <Grid>
//             {FEATURES.map((feature) => (
//               <FeatureItem title={feature.title} pictureUrl={feature.picture_url} />
//             ))}
//           </Grid>
//         </Section>
//         <Section>
//           <div className="title">สร้างไวด้วยเทมเพลทพร้อมใช้งาน</div>
//           <div className="description">
//             ง่ายๆ เพียงเลือกแชทบอท Template แล้วใส่ข้อมูลธุรกิจของคุณ ในยุควิกฤตเช่นนี้
//             ร้านไหนบริการดี บริการไว ทันใจลูกค้า ได้เปรียบแน่นอน
//           </div>
//           <Spacer height={30} />
//           <BotTemplate items={BOT_TEMPLATES} />
//         </Section>
//         <Section>
//           <div className="title">ค่าบริการรายเดือน</div>
//           <PriceTable />
//         </Section>
//         <Section highlight>
//           <div className="title">สอบถามข้อมูลเพิ่มเติม</div>
//           <div className="description">
//             หากมีข้อสงสัยใดเกี่ยวกับผลิตภัณฑ์ของเรา สามารถแชทพูดคุยสอบถามได้ตลอด 24 ช.ม.
//           </div>
//           <div style={{ textAlign: 'center', marginTop: 20 }}>
//             <Button>ติดต่อฝ่ายลูกค้าสัมพันธ์</Button>
//           </div>
//         </Section>
//       </Main>
//     </Layout>
//   )
// }
